/*
|--------------------------------------------------------------------------
| Habitaciones
|--------------------------------------------------------------------------
*/
.Habitaciones__Caracteristicas {
	background: $beige-bg;
	padding: 1rem;
	& > div {
		padding: 10px;
	}
	ul {
		list-style-image: url(/images/svg/brown-bullet.svg);
		li {
			margin-left: 20px;
			padding-left: 0;
		}
	}
}
.Habitaciones__Icons {
	& > div {
		margin-bottom: 1rem;
	}
	img {
		margin-bottom: 0.5rem;
		max-width: 30px;
		width: 100%;
	}
	p {
		line-height: 1;
	}
}
.Habitaciones__Otras {
	background: $sense-bg url(/images/bg/paquetes-brown-bg.png) top left;
	.Otras-frame {
		position: relative;
		display: block;
		&:after {
			content: '';
			position: absolute;
			top: 5%;
			left: 5%;
			width: 90%;
			height: 90%;
			margin: auto;
			border: 1px solid white;
			z-index: 50;
		}
		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: rgba(107, 94, 88, 0.3);
			z-index: 10;
			transition: background 0.5s;
		}
		&:hover {
			&:before {
				background: rgba(107, 94, 88, 0.5);
			}
		}
		h4 {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			display: block;
			color: white;
			font-size: 30px;
			text-transform: uppercase;
			text-align: center;
			width: 100%;
			font-weight: 300;
			z-index: 50;
			span {
				display: block;
				font-size: 18px;
				text-transform: none;
			}
		}
	}
}
.Habitaciones__Content {
	background: $beige-bg url(/images/bg/paquetes-bg.png) top left;
}
