/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/
footer {
	padding: 3rem 0;
	background: url(/images/svg/patron-footer.svg) top left;
	background-color: $gray;
	img.logo-footer {
		max-width: 270px;
	}
	address {
		color: white;
	}
	ul.social-footer {
		margin-top: 2rem;
		li {
			&:first-child {
				a {
					margin-left: 0;
				}
			}
			a {
				color: white;
				display: block;
				margin: 0 13px;
				i.fa {
					font-size: 26px;
				}
			}
		}
	}
	hr.footer-separator {
		border: none;
		height: 2px;
		margin: 1.5rem auto;
		background-color: lighten($gray, 10%);
		width: 100%;
		display: block;
		clear: both;
	}
	ul.footer__phones {
		li {
			color: white;
			margin-bottom: 1rem;
			font-size: 20px;
			i.fa {
				display: block;
				float: none;
				@media (min-width: 640px) {
					float: left;
				}
				margin-right: 1rem;
				font-size: 24px;
			}
		}
	}

	.footer-brands {
		max-height: 60px;
	}

	a.footer-social {
		cursor: pointer;
		color: white;
		margin: 15px;
		font-size: 20px;
	}
	a.footer-social:hover {
		color: $orange;
	}
	a {
		color: $orange;
	}

}
.footer-bottom {
	background-color: darken($gray, 10%);
	color: white;
	text-align: center;
	font-size: 13px;
	padding: 0;
	line-height: 20px;
}
