/*
|--------------------------------------------------------------------------
| Pages
|--------------------------------------------------------------------------
*/
.Page__Header {
	padding: 10px 0;
	h1, h2 {
		text-transform: uppercase;
		font-size: 36px;
		font-weight: 100;
		text-align: center;
		margin: 10px auto;
		border: 4px double $gray;
		padding: 1rem 0;
	}
}
.Paquetes__Content {
	&.paquetes {
		background: $sense-bg url(/images/bg/paquetes-bg.png) top left;
	}
	&.cena {
		background: $beige-bg url(/images/bg/paquetes-cena-bg.png) top left;
	}
}
.Paquete__Image {
	position: relative;
	display: block;
	&:after {
		position: absolute;
		top: 5%;
		left: 5%;
		content: '';
		border: 1px solid white;
		display: block;
		width: 90%;
		height: 90%;
	}
}
.Paquete__details {
	background-color: $gray;
	color: white;
	line-height: 30px;
	padding: 9px 10px;
	span.theSpan {
		text-align: center;
		text-transform: capitalize;
		font-size: 24px;
		display: block;
		@media (min-width: 767px) {
			font-size: 30px;
		}
	}
}
.Paquetes__Restrictions {
	background-color: $beige;
	text-align: center;
	padding: 1rem 0;
	p {
		margin-bottom: 0;
	}
}
.Page__Image-header {
	min-height: 350px;
	background-size: cover;
	background-position: center center;
	position: relative;
	&:before,
	&:after {
		content: '';
		width: 100%;
		height: 1px;
		background-color: white;
		left: 0;
		position: absolute;
	}
	&:before { top: 1rem; }
	&:after { bottom: 1rem; }
}
.Pages__quote {
	width: 70%;
	margin: 2rem auto;
	border: 1px solid $brown;
	text-align: center;
	font-size: 14px;
	padding: 10px 0;
	span.cantidad {
		font-size: 40px;
		font-weight: 300;
		color: $brown;
		display: block;
		line-height: 1.1;
	}
}
.download-frame {
	text-align: center;
	padding: 1rem;
	border: 1px solid $brown;
	width: 70%;
	margin: -1px auto 0 auto;
	@media (min-width: 767px) {
		width: 30%;
	}
	i.fa {
		color: $brown;
		font-size: 26px;
		margin: 0 10px;
	}
}
.Mosaico {
	height: 10px;
	background-color: white;
	position: relative;
	img {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
		max-width: 50px;
	}
}
.Galeria__Content {
	&.vivetequis {
		background: $sense-bg url(/images/bg/paquetes-bg.png) top left;
	}
	&.sense {
		background: $beige-bg url(/images/bg/paquetes-cena-bg.png) top left;
	}
	&.instalaciones {
		background: $sense-bg url(/images/bg/paquetes-bg.png) top left;
	}
	&.grupos {
		background: $beige-bg url(/images/bg/paquetes-cena-bg.png) top left;
	}
}
/*
|--------------------------------------------------------------------------
|	Spacio Sense
|--------------------------------------------------------------------------
*/
.Sense__bg {
	background: url('/images/bg/paquetes-brown-bg.png') left top;
}
.Espacio__Caracteristicas {
	background: $beige-bg;
	padding: 1rem;
	& > div {
		padding: 10px;
	}
	ul {
		list-style-image: url(/images/svg/morado-bullet.svg);
		li {
			margin-left: 20px;
			padding-left: 0;
		}
	}
}
.Phone__Frame {
	font-weight: 300;
	border: 1px solid $morado;
	padding: 6px;
	line-height: 1;
}
.Sense__Image-header {
	min-height: 500px;
	background-size: cover;
	background-position: center center;
	position: relative;
	&:before,
	&:after {
		content: '';
		width: 100%;
		height: 1px;
		background-color: white;
		left: 0;
		position: absolute;
	}
	&:before { top: 1rem; }
	&:after { bottom: 1rem; }
}
