/*
|--------------------------------------------------------------------------
| Forms
|--------------------------------------------------------------------------
*/
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: lighten($gray, 15%);
}
::-moz-placeholder { /* Firefox 19+ */
  color: lighten($gray, 15%);
}
:-ms-input-placeholder { /* IE 10+ */
  color: lighten($gray, 15%);
}
:-moz-placeholder { /* Firefox 18- */
  color: lighten($gray, 15%);
}

.Booking__Pages {
	background: rgba(107, 94, 88, 0.85);
	padding: 14px 0;
}
