/*
|--------------------------------------------------------------------------
| La Casona Styles
|--------------------------------------------------------------------------
*/
$font-roboto: 'Roboto', sans-serif;
$font-open: 'Open Sans', sans-serif;
@import "utilities/colors";

@import "utilities/general";
@import "elements/modal";
@import "sections/pages";
@import "sections/habitaciones";
@import "utilities/setters";
@import "sections/header";
@import "elements/home";
@import "elements/sliders";
@import "elements/forms";
@import "sections/footer";
@import "sections/spa";
@import "sections/grupos";
@import "sections/admin";
@import "sections/tequis";
@import "sections/globo";
@import "sections/contacto";
@import "sections/queso";
@import "sections/instalaciones";
