.Instalaciones__Section {	
	h3.Instalaciones__Header {
			font-size: 25px;
			color: $darkgray;
			font-weight: 300;
			margin-top: 50%;
			i {
				font-size: 40px;
			}
	}

	.Instalaciones__seccionl {
		width: 60%;
	}

	.deco-medioambiente {
		background: url(/images/instalaciones/bloquedeco-medioambiente.png) no-repeat center center;
	}


	.para_sustentables {
	background: url(/images/bg/ruta-queso-y-vino.jpg);
	background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;

    padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	
	.container {
		z-index: 30;
	}
	&:after {
		transition: opacity 0.4s;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.4;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.2;
		}
	}

    hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}

}
}
