.Contacto__Section {	
	h3.Contacto__Header {
			font-size: 30px;
			color: $brown;
			font-weight: 300;
	}
	h4.Contacto__Header {
			font-size: 25px;
			color: $brown;
			font-weight: 300;
	}
	i {
		margin-right: 10px;
	}
}
