/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
body {
	font-size: 16px;
	color: $gray;
	font-family: $font-roboto;
	font-weight: 300;
}
a {
	color: $gray;
	transition: all 0.7s;
	&:hover {
		text-decoration: none;
		color: $orange;
	}
	&:active {
		color: $gray;
		text-decoration: none;
	}
	&:focus {
		color: $gray;
		text-decoration: none;
	}
}
hr.simpleSeparator {
	width: 100%;
	display: block;
	margin: 0 auto;
	clear: both;
	height: 1px;
	border: none;
	background: transparent;
}
hr.separator {
	@extend hr.simpleSeparator;
	margin: 1rem auto;
}
hr.bigSeparator {
	@extend hr.simpleSeparator;
	margin: 3rem auto;
}
hr.brownSeparator {
	width: 100%;
	display: block;
	margin: 10px auto;
	clear: both;
	height: 1px;
	background-color: $brown;
	border: none;
}
hgroup {
	&.Home__Group {
		h1 {
			font-size: 30px;
			font-weight: 300;
			color: $brown;
		}
		h2 {
			font-size: 19px;
			font-weight: 300;
		}
	}
}
img.tripadvisor {
	max-width: 100px;
	max-height: 100px;
}
.Special__Text {
	width: 100%;
	@media (min-width: 1199px) {
		width: 80%;
	}
}


.parallax-container{
	position:relative;
	overflow:hidden;
	height:500px;
	.parallax{
		position:absolute;
		top:0;
		left:0;
		right:0;
		bottom:0;
		z-index:-1;

		img{
			display:none;
			position:absolute;
			left:50%;
			bottom:0;
			min-width:100%;
			min-height:100%;
		}
	}
}

#botongotop {
    display: none;
    position: fixed;
    bottom: 20px;
    right: 30px;
    z-index: 99;
    border: none;
    outline: none;
    background-color: white;
    color: $orange;
    cursor: pointer;
    padding: 15px;
    border-radius: 10px;
}

#botongotop:hover {
    background-color: $orange;
    color: white;
}




