.Queso__Section {	
	h3.Queso__Header {
			font-size: 30px;
			color: $brown;
			font-weight: 300;
	}
}


.Quesocal__Section {
	background: $azulverde;

	.tit_calendar {
		font-size: 20px;
		line-height: 40px;
		color: $rosacalendar;
	}

	.icono-calendar {
		max-width: 40px;
		float: left;
		padding: 5px;
	}

	.tit_subcalendar {
		font-size: 15px;
		line-height: 20px;
		color: $orange;
	}

	em {
		font-size: 15px;
		color: $darkgray;
	}

	.sinicono {
		margin-left: 40px;
	}
}

	