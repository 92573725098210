.Grupos__Section {
	h3.Grupos__Header {
		font-size: 30px;
		color: $brown;
		font-weight: 300;
	}
	.divizq {
		ul {
			list-style: none;
			li {
				text-align: right;
				padding-right: 20px;
			}
		}
		padding-right: 0;
	}
	.divder {
		ul {
			padding-left: 0px;
			list-style: none;
			li {
				padding-left: 20px;
				text-align: left;
			}
		}
		padding-left: 0;
	}
	h4.List__Header {
		font-size: 18px;
		span {
			font-size: 16px;
			font-style: italic;
			font-weight: 300;
		}
	}
	ul.grupos-list {
		list-style: none;
		padding-left: 1rem;
		margin-bottom: 0;
		&.gray {
			background-color: $lightgray;
			padding: 1rem;
			margin-bottom: 1rem;
			li:last-child {
				margin-bottom: 0;
			}
		}
		li {
			margin-bottom: 14px;
			overflow: hidden;
			position: relative;
			img {
				max-width: 36px;
				float: left;
				margin: 0 10px 0 0;
			}
			p {
				text-align: left;
				font-size: 13px;
				line-height: 1.4;
				margin-bottom: 0;
			}
			ul {
				position: relative;
				list-style: none;
				&:before {
					position: absolute;
					content: '';
					width: 1px;
					height: 73%;
					background: $lightgray;
					top: 0;
					left: 1rem;
					overflow: hidden;
				}
				@media (min-width: 576px) and (max-width: 767px) {
					&:before {
						height: 77%;
					}
				}
				li {
					position: relative;
					overflow: visible;
					&:before {
						position: absolute;
						content: '';
						width: 24px;
						height: 1px;
						background: $lightgray;
						top: 50%;
						left: -24px;
					}
				}
			}
		}
	}
}

.tit_capac {
	color: $brown;
	font-size: 20px;
	margin-top: 50px;
}

.Reuniones_empresariales {
	background: url(/images/bg/empresariales.jpg);
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	.container {
		z-index: 30;
	}
	&:after {
		transition: opacity 0.4s;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.4;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.2;
		}
	}
	hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}
}
.Banquetes {
	background: url(/images/bg/grupos.jpg);
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	.container {
		z-index: 30;
	}
	&:after {
		transition: opacity 0.4s;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.4;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.2;
		}
	}
	hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}
}
.Viajes_incentivo {
	background: url(/images/bg/incentivo.jpg);
	background-attachment: fixed;
	background-repeat: no-repeat;
	position: relative;
	padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	.container {
		z-index: 30;
	}
	&:after {
		transition: opacity 0.4s;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.4;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.2;
		}
	}
	hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}
}
