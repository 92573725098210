/*
|--------------------------------------------------------------------------
|	General
|--------------------------------------------------------------------------
*/
h1, h2, h3, h4 {
	color: black;
}



/*
|--------------------------------------------------------------------------
|	Admin Styles
|--------------------------------------------------------------------------
*/
nav.navbar {
	ul.navbar-nav {
		@media (min-width: 767px) {
			margin-left: 0;
		}
		li {
			a {
				color: white;
				&:hover {
					color: $orange;
				}
			}
		}
	}
}

/*
|--------------------------------------------------------------------------
|	Tables
|--------------------------------------------------------------------------
*/
table.table {
	tbody {
		tr {
			td.toCenter {
				text-align: center;
			}
		}
	}
	thead {
		tr {
			th.toCenter {
				text-align: center;
			}
		}
	}
}

/*
|--------------------------------------------------------------------------
|	Forms
|--------------------------------------------------------------------------
*/
form {
	.form-group {
		label {
			font-weight: bold;
			margin-bottom: 0;
			color: black;
		}
	}
}

