.Spa__Section {	
	h3.Spa__Header {
			font-size: 30px;
			color: $brown;
			font-weight: 300;
	}

	ul.lista-spa {
		list-style: none;
		color: $brown;
		li {
			img {
				vertical-align: 1px;
				margin-right: 10px;
			}
		}
	}
}
