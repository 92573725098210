/*
|--------------------------------------------------------------------------
| Home
|--------------------------------------------------------------------------
*/
.Home__Habitaciones {
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: $beige-bg;
	position: relative;
	&:after,
	&:before {
		position: absolute;
		top: 1px;
		width: 100%;
		max-width: 400px;
		height: 358px;
	}
	&:before {
		content: url(/images/svg/mosaico-left.svg);
		left: -396px;
	}
	&:after {
		content: url(/images/svg/mosaico-right.svg);
		right: -386px;
	}
	h3.Habitaciones__Header {
		font-size: 30px;
		color: $brown;
		font-weight: 300;
	}
}
.Home__Videos {
	padding-top: 15px;
	padding-bottom: 15px;
	background-image: url(/images/bg/home-videos.png);
	background-repeat: no-repeat;
	background-size: cover;
	background-color: $gray;
	padding: 1rem 0;
	.home_video {
		position: relative;
		border: 1px solid white;
		padding: 10px;
		position: relative;
		img.frame {
			width: 100%;
			border: 1px solid white;
			padding: 10px;
		}
		img.player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}
}
hr.shadowed {
	width: 100%;
	height: 1px;
	border: none;
	display: block;
	background-color: transparent;
	box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.5);
	&.inverted {
		box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
	}
}
.Home__Logos {
	padding-top: 15px;
	padding-bottom: 15px;
	overflow: hidden;
	hr.simpleLine {
		height: 1px;
		background-color: $beige;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
	}
}
.Home__Ruta_stellar {
	background: url(/images/bg/ruta-queso-y-vino.jpg);
	background-attachment: fixed;
    background-repeat: no-repeat;
    height: 450px;
    position: relative;

}

.Home__Ruta {
	padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	position: relative;
	.container {
		z-index: 30;
	}
	&:after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.5;
		transition: opacity 0.7s;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.3;
		}
	}
	hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}
}
.Home__Tequisquiapan {
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: $beige-bg;
	position: relative;
	&:after,
	&:before {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 385px;
		height: 100%;
	}
	&:before {
		content: url(/images/svg/mosaico-tequis-left.svg);
		left: -385px;
	}
	&:after {
		content: url(/images/svg/mosaico-tequis-right.svg);
		right: -390px;
	}
	.container {
		z-index: 100;
	}
	h3.Habitaciones__Header {
		font-size: 30px;
		color: $brown;
		font-weight: 300;
	}
}
.Home__Grupos {
	padding-top: 15px;
	padding-bottom: 15px;
	background-color: $sense-bg;
	position: relative;
	&:after,
	&:before {
		position: absolute;
		top: 0;
		width: 100%;
		max-width: 385px;
		height: 100%;
	}
	&:before {
		content: url(/images/svg/mosaico-tequis-left.svg);
		left: -385px;
	}
	&:after {
		content: url(/images/svg/mosaico-tequis-right.svg);
		right: -390px;
	}
	.container {
		z-index: 100;
	}
	h3.Habitaciones__Header {
		font-size: 30px;
		color: $brown;
		font-weight: 300;
	}
}
.Cena__romantica_stellar {
	background: url(/images/bg/cena-romantica.jpg);
	background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
}
.Cena__romantica {
	padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	position: relative;
	.container {
		z-index: 30;
	}
	&:after {
		transition: opacity 0.6s;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.3;
		}
	}
	hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}
}

.Home__Globo_stellar {
	background: url(/images/bg/vuelo-en-globo.jpg);
	background-attachment: fixed;
    background-repeat: no-repeat;
    position: relative;
}
.Home__Globo {
	padding-top: 15px;
	padding-bottom: 15px;
	padding: 1rem 0;
	position: relative;
	.container {
		z-index: 30;
	}
	&:after {
		transition: opacity 0.6s;
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: $brown;
		opacity: 0.6;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 10;
	}
	&:hover {
		&:after {
			opacity: 0.3;
		}
	}
	hr.whiteLine {
		height: 1px;
		background-color: white;
		width: 100%;
		margin: 0 auto;
		clear: both;
		border: none;
		position: relative;
		z-index: 30;
	}
}
.Home__Map {
	padding-top: 15px;
	padding-bottom: 15px;
	span.direccion {
		text-transform: uppercase;
		font-size: 30px;
		font-weight: 400;
		color: white;
	}
	select.mapaGoogle {
		vertical-align: super;
		margin: 10px 0 10px 13px;
		border-color: $gray;
		height: 30px;
		color: lighten($gray, 15%);
	}
}

.Home__Sense {
	padding-top: 15px;
	padding-bottom: 15px;
}

#CDSWIDGRN.widGRN .footer {
	height: unset !important;
}

#closeModal {
	position: absolute;
	top: -87px;
    right: 150px;
	color: white;
	font-size: 30px;
	a {
		color: white;
		font-size: inherit;
	}
}
#closeModalMovil {
	position: fixed;
	top: 10px;
    right: 10px;
	color: white;
	font-size: 30px;
	a {
		color: white;
		font-size: inherit;
	}
}
#contenedor-modal {
	position: relative;
}

.iframehomevid {
	height: 500px;
	@media (max-width: 767px) {
		height: 200px;
	}
}
