/*
|--------------------------------------------------------------------------
| Home Sliders
|--------------------------------------------------------------------------
*/
.Slider__Container {
	position: relative;
	.Booking__Home {
		background: rgba(107, 94, 88, 0.75);
		padding: 2rem 0;
		position: relative;
		bottom: 0;
		left: 0;
		width: 100%;
		@media (min-width: 640px) {
			position: absolute;
		}
	}
	.Home__Slider {
		margin-bottom: 0;
		@media (min-width: 640px) {
			&.slick-slider {
				margin-bottom: 30px;
			}
		}
		ul.slick-dots {
			bottom: 280px;
			display: none !important;
			@media (max-width: 767px) {
				display: none !important;
			}
			// @media (min-width: 437px) and(max-width: 767px) {
			// 	bottom: 235px;
			// }
			@media (min-width: 768px) and (max-width: 991px) {
				bottom: 180px;
				display: block;
			}
			@media (min-width: 992px) {
				bottom: 135px;
				display: block;
			}
			li {
				button:before {
					font-size: 16px;
					background-color: $beige;
					border-radius: 50%;
					color: $beige;
					border: 1px solid $beige;
					opacity: 0.75;
				}
			}
			li.slick-active {
				button:before {
					background-color: $orange;
					border-radius: 50%;
					color: $orange;
					border: 1px solid $beige;
					opacity: 1;

				}
			}
		}
	}
	.slick-slide {
		img {
			width: 100%;
		}
	}
}


.Habitaciones__Slider {
	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	ul.slick-dots {
		bottom: 30px;
		li {
			button {
				&:before {
					background-color: $beige;
					border-radius: 50%;
					color: $beige;
					border: 1px solid $beige;
					opacity: 0.75;
				}
			}
			&.slick-active {
				button {
					&:before {
						background-color: $orange;
						border-radius: 50%;
						color: $orange;
						border: 1px solid $beige;
						opacity: 1;
					}
				}
			}
		}
	}
}
.Sense__Slider {
	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	background-color: white;
	ul.slick-dots {
		bottom: 30px;
		li {
			button {
				&:before {
					background-color: $beige;
					border-radius: 50%;
					color: $beige;
					border: 1px solid $beige;
					opacity: 0.75;
				}
			}
			&.slick-active {
				button {
					&:before {
						background-color: $orange;
						border-radius: 50%;
						color: $orange;
						border: 1px solid $beige;
						opacity: 1;
					}
				}
			}
		}
	}
}
.Paquetes__Slider {
	&.slick-dotted.slick-slider {
		margin-bottom: 4rem;
	}
	ul.slick-dots {
		bottom: -40px;
		text-align: center;
		li {
			button {
				&:before {
					background-color: $beige;
					border-radius: 50%;
					color: $beige;
					border: 1px solid $beige;
					opacity: 0.75;
				}
			}
			&.slick-active {
				button {
					&:before {
						background-color: $orange;
						border-radius: 50%;
						color: $orange;
						border: 1px solid $beige;
						opacity: 1;
					}
				}
			}
		}
	}
	div {
		position: relative;
		img {
			width: 94%;
			margin: 0 auto;
		}
		.Promos__Content {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			&:after {
				position: absolute;
				top: 5%;
				left: 5%;
				width: 90%;
				height: 90%;
				display: block;
				margin: auto;
				content: '';
				border: 1px solid white;
				background: rgba(107, 94, 88, 0.4);
				z-index: 50;
			}
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				color: white;
				z-index: 100;
				font-size: 16px;
				width: 90%;
				text-align: center;
				text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
				@media (min-width: 767px) {
					font-size: 20px;
					width: 70%;
				}
			}
		}
	}
}
.Habitaciones__small {
	margin: 2rem 0;
	display: none;
	z-index: 100;
	@media (min-width: 767px) {
		display: block;
	}
	.slick-current {
		img {
			border: 2px solid $orange;
			box-shadow: 0 0 1px black;
		}
		&:focus {
			outline: none;
		}
	}
}
.newarrows {
	position: absolute;
	top: 34%;
	left: -1%;
	width: 100%;
	display: none;
	color: $beige;
	z-index: 10;
	&.sense {
		color: $morado;
	}
	@media (min-width: 767px) {
		display: block;
	}
	i.fa {
		font-size: 36px;
		cursor: pointer;
		position: absolute;
		left: 0;
		&.righticon {
			right: -2% !important;
			left: auto;
		}
	}
}
.Gal__Slider {
	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	ul.slick-dots {
		bottom: 30px;
		li {
			button {
				&:before {
					background-color: $beige;
					border-radius: 50%;
					color: $beige;
					border: 1px solid $beige;
					opacity: 0.75;
				}
			}
			&.slick-active {
				button {
					&:before {
						background-color: $orange;
						border-radius: 50%;
						color: $orange;
						border: 1px solid $beige;
						opacity: 1;
					}
				}
			}
		}
	}
}
.Gal__small {
	margin: 2rem 0;
	display: none;
	z-index: 100;
	@media (min-width: 767px) {
		display: block;
	}
	.slick-current {
		img {
			border: 2px solid $orange;
			box-shadow: 0 0 1px black;
		}
		&:focus {
			outline: none;
		}
	}
}
.SenseHome__Slider {
	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
}
.SenseHome__small {
	margin: 2rem 0;
	display: none;
	z-index: 100;
	@media (min-width: 767px) {
		display: block;
	}
	.slick-current {
		img {
			border: 2px solid $espacio;
			box-shadow: 0 0 1px black;
		}
		&:focus {
			outline: none;
		}
	}
}

.Tequis__Slider {
	&.slick-dotted.slick-slider {
		margin-bottom: 0;
	}
	background-color: white;
	ul.slick-dots {
		bottom: 30px;
		li {
			button {
				&:before {
					background-color: $beige;
					border-radius: 50%;
					color: $beige;
					border: 1px solid $beige;
					opacity: 0.75;
				}
			}
			&.slick-active {
				button {
					&:before {
						background-color: $orange;
						border-radius: 50%;
						color: $orange;
						border: 1px solid $beige;
						opacity: 1;
					}
				}
			}
		}
	}
}
