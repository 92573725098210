/*
|--------------------------------------------------------------------------
| La Casona Styles
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
body {
  font-size: 16px;
  color: #6B5E58;
  font-family: "Roboto", sans-serif;
  font-weight: 300;
}

a {
  color: #6B5E58;
  transition: all 0.7s;
}

a:hover {
  text-decoration: none;
  color: #E37523;
}

a:active {
  color: #6B5E58;
  text-decoration: none;
}

a:focus {
  color: #6B5E58;
  text-decoration: none;
}

hr.simpleSeparator, hr.separator, hr.bigSeparator {
  width: 100%;
  display: block;
  margin: 0 auto;
  clear: both;
  height: 1px;
  border: none;
  background: transparent;
}

hr.separator {
  margin: 1rem auto;
}

hr.bigSeparator {
  margin: 3rem auto;
}

hr.brownSeparator {
  width: 100%;
  display: block;
  margin: 10px auto;
  clear: both;
  height: 1px;
  background-color: #975010;
  border: none;
}

hgroup.Home__Group h1 {
  font-size: 30px;
  font-weight: 300;
  color: #975010;
}

hgroup.Home__Group h2 {
  font-size: 19px;
  font-weight: 300;
}

img.tripadvisor {
  max-width: 100px;
  max-height: 100px;
}

.Special__Text {
  width: 100%;
}

@media (min-width: 1199px) {
  .Special__Text {
    width: 80%;
  }
}

.parallax-container {
  position: relative;
  overflow: hidden;
  height: 500px;
}

.parallax-container .parallax {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.parallax-container .parallax img {
  display: none;
  position: absolute;
  left: 50%;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

#botongotop {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: white;
  color: #E37523;
  cursor: pointer;
  padding: 15px;
  border-radius: 10px;
}

#botongotop:hover {
  background-color: #E37523;
  color: white;
}

/*
|--------------------------------------------------------------------------
|	Modal
|--------------------------------------------------------------------------
*/
.Home__Intro {
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  background: rgba(79, 69, 65, 0.9);
  z-index: 1000;
  overflow: scroll;
  height: 100%;
  padding-top: 1rem;
}

@media (min-width: 767px) {
  .Home__Intro {
    padding-top: auto;
  }
}

.Home__Intro.remove {
  display: none !important;
}

.Home__Intro .Home__Intro-container {
  position: relative;
  width: 100%;
  max-height: 180px;
  max-width: 300px;
  z-index: 1000;
  margin-bottom: 1rem;
  overflow: hidden;
  cursor: pointer;
}

@media (min-width: 767px) {
  .Home__Intro .Home__Intro-container {
    max-height: none;
    margin-bottom: 0;
  }
}

.Home__Intro .Home__Intro-container:after {
  top: 5%;
  left: 5%;
  position: absolute;
  width: 90%;
  height: 90%;
  content: '';
  border: 1px solid white;
  z-index: 1009;
}

.Home__Intro .Home__Intro-container img {
  width: 100%;
}

.Home__Intro .Home__Intro-container h3 {
  font-weight: 300;
  font-size: 20px;
  color: white;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  padding: 11px;
  z-index: 1001;
}

@media (min-width: 767px) {
  .Home__Intro .Home__Intro-container h3 {
    top: auto;
    bottom: 10%;
    padding: 1rem;
  }
}

.Home__Intro .Home__Intro-container h3.familiar {
  background: rgba(227, 117, 35, 0.8);
}

.Home__Intro .Home__Intro-container h3.romantica {
  background: rgba(133, 39, 129, 0.7);
}

.Home__Intro .Home__Intro-container h3.grupos {
  background: rgba(107, 94, 88, 0.7);
}

.Home__Intro .Home__Intro-container .Entrar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 2000;
  opacity: 0;
  transition: opacity 0.7s;
}

.Home__Intro .Home__Intro-container .Entrar span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%;
  color: white;
  z-index: 2000;
  text-transform: uppercase;
}

.Home__Intro .Home__Intro-container:hover .Entrar {
  opacity: 1;
}

@media (max-width: 767px) {
  .Home__Intro .Home__Intro-container:hover .Entrar {
    display: none;
  }
}

.Home__Intro .Home__Intro-container:hover .Entrar.familiar {
  background: rgba(227, 117, 35, 0.6);
}

.Home__Intro .Home__Intro-container:hover .Entrar.romantica {
  background: rgba(133, 39, 129, 0.5);
}

.Home__Intro .Home__Intro-container:hover .Entrar.grupos {
  background: rgba(107, 94, 88, 0.5);
}

.modal-backdrop {
  opacity: 0.8;
}

/*
|--------------------------------------------------------------------------
| Pages
|--------------------------------------------------------------------------
*/
.Page__Header {
  padding: 10px 0;
}

.Page__Header h1, .Page__Header h2 {
  text-transform: uppercase;
  font-size: 36px;
  font-weight: 100;
  text-align: center;
  margin: 10px auto;
  border: 4px double #6B5E58;
  padding: 1rem 0;
}

.Paquetes__Content.paquetes {
  background: #dee7e8 url(/images/bg/paquetes-bg.png) top left;
}

.Paquetes__Content.cena {
  background: #F6F4EE url(/images/bg/paquetes-cena-bg.png) top left;
}

.Paquete__Image {
  position: relative;
  display: block;
}

.Paquete__Image:after {
  position: absolute;
  top: 5%;
  left: 5%;
  content: '';
  border: 1px solid white;
  display: block;
  width: 90%;
  height: 90%;
}

.Paquete__details {
  background-color: #6B5E58;
  color: white;
  line-height: 30px;
  padding: 9px 10px;
}

.Paquete__details span.theSpan {
  text-align: center;
  text-transform: capitalize;
  font-size: 24px;
  display: block;
}

@media (min-width: 767px) {
  .Paquete__details span.theSpan {
    font-size: 30px;
  }
}

.Paquetes__Restrictions {
  background-color: #DCD4BB;
  text-align: center;
  padding: 1rem 0;
}

.Paquetes__Restrictions p {
  margin-bottom: 0;
}

.Page__Image-header {
  min-height: 350px;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.Page__Image-header:before, .Page__Image-header:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  left: 0;
  position: absolute;
}

.Page__Image-header:before {
  top: 1rem;
}

.Page__Image-header:after {
  bottom: 1rem;
}

.Pages__quote {
  width: 70%;
  margin: 2rem auto;
  border: 1px solid #975010;
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
}

.Pages__quote span.cantidad {
  font-size: 40px;
  font-weight: 300;
  color: #975010;
  display: block;
  line-height: 1.1;
}

.download-frame {
  text-align: center;
  padding: 1rem;
  border: 1px solid #975010;
  width: 70%;
  margin: -1px auto 0 auto;
}

@media (min-width: 767px) {
  .download-frame {
    width: 30%;
  }
}

.download-frame i.fa {
  color: #975010;
  font-size: 26px;
  margin: 0 10px;
}

.Mosaico {
  height: 10px;
  background-color: white;
  position: relative;
}

.Mosaico img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 0 1px 2px rgba(0, 0, 0, 0.2);
  max-width: 50px;
}

.Galeria__Content.vivetequis {
  background: #dee7e8 url(/images/bg/paquetes-bg.png) top left;
}

.Galeria__Content.sense {
  background: #F6F4EE url(/images/bg/paquetes-cena-bg.png) top left;
}

.Galeria__Content.instalaciones {
  background: #dee7e8 url(/images/bg/paquetes-bg.png) top left;
}

.Galeria__Content.grupos {
  background: #F6F4EE url(/images/bg/paquetes-cena-bg.png) top left;
}

/*
|--------------------------------------------------------------------------
|	Spacio Sense
|--------------------------------------------------------------------------
*/
.Sense__bg {
  background: url("/images/bg/paquetes-brown-bg.png") left top;
}

.Espacio__Caracteristicas {
  background: #F6F4EE;
  padding: 1rem;
}

.Espacio__Caracteristicas > div {
  padding: 10px;
}

.Espacio__Caracteristicas ul {
  list-style-image: url(/images/svg/morado-bullet.svg);
}

.Espacio__Caracteristicas ul li {
  margin-left: 20px;
  padding-left: 0;
}

.Phone__Frame {
  font-weight: 300;
  border: 1px solid #852781;
  padding: 6px;
  line-height: 1;
}

.Sense__Image-header {
  min-height: 500px;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.Sense__Image-header:before, .Sense__Image-header:after {
  content: '';
  width: 100%;
  height: 1px;
  background-color: white;
  left: 0;
  position: absolute;
}

.Sense__Image-header:before {
  top: 1rem;
}

.Sense__Image-header:after {
  bottom: 1rem;
}

/*
|--------------------------------------------------------------------------
| Habitaciones
|--------------------------------------------------------------------------
*/
.Habitaciones__Caracteristicas {
  background: #F6F4EE;
  padding: 1rem;
}

.Habitaciones__Caracteristicas > div {
  padding: 10px;
}

.Habitaciones__Caracteristicas ul {
  list-style-image: url(/images/svg/brown-bullet.svg);
}

.Habitaciones__Caracteristicas ul li {
  margin-left: 20px;
  padding-left: 0;
}

.Habitaciones__Icons > div {
  margin-bottom: 1rem;
}

.Habitaciones__Icons img {
  margin-bottom: 0.5rem;
  max-width: 30px;
  width: 100%;
}

.Habitaciones__Icons p {
  line-height: 1;
}

.Habitaciones__Otras {
  background: #dee7e8 url(/images/bg/paquetes-brown-bg.png) top left;
}

.Habitaciones__Otras .Otras-frame {
  position: relative;
  display: block;
}

.Habitaciones__Otras .Otras-frame:after {
  content: '';
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  margin: auto;
  border: 1px solid white;
  z-index: 50;
}

.Habitaciones__Otras .Otras-frame:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(107, 94, 88, 0.3);
  z-index: 10;
  transition: background 0.5s;
}

.Habitaciones__Otras .Otras-frame:hover:before {
  background: rgba(107, 94, 88, 0.5);
}

.Habitaciones__Otras .Otras-frame h4 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  color: white;
  font-size: 30px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  font-weight: 300;
  z-index: 50;
}

.Habitaciones__Otras .Otras-frame h4 span {
  display: block;
  font-size: 18px;
  text-transform: none;
}

.Habitaciones__Content {
  background: #F6F4EE url(/images/bg/paquetes-bg.png) top left;
}

/*
|--------------------------------------------------------------------------
| Setters
|--------------------------------------------------------------------------
*/
.oh {
  overflow: hidden;
}

.pos-rel {
  position: relative;
}

/*
|--------------------------------------------------------------------------
| Buttons
|--------------------------------------------------------------------------
*/
a.btn,
button.btn {
  transition: all 0.7s;
}

a.btn:focus, a.btn.active,
button.btn:focus,
button.btn.active {
  outline: none;
}

a.btn.orange,
button.btn.orange {
  background: #E37523;
  color: white;
  border: 1px solid transparent;
}

a.btn.orange:hover,
button.btn.orange:hover {
  background: white;
  color: #E37523 !important;
  border-color: #E37523;
}

a.btn.beige,
button.btn.beige {
  background: #DCD4BB;
  border: 1px solid transparent;
}

a.btn.beige:hover,
button.btn.beige:hover {
  background: white;
  color: #6B5E58;
  border-color: #DCD4BB;
}

a.btn.orange-ghost,
button.btn.orange-ghost {
  background: transparent;
  border: 1px solid #E37523;
}

a.btn.orange-ghost:hover,
button.btn.orange-ghost:hover {
  color: white;
  background: #E37523;
}

a.btn.violeta-ghost,
button.btn.violeta-ghost {
  background: transparent;
  border: 1px solid #dfd7e8;
}

a.btn.violeta-ghost:hover,
button.btn.violeta-ghost:hover {
  color: white;
  background: #dfd7e8;
}

a.btn.morado-ghost,
button.btn.morado-ghost {
  background: transparent;
  border: 1px solid #852781;
}

a.btn.morado-ghost:hover,
button.btn.morado-ghost:hover {
  color: white;
  background: #852781;
}

a.btn.white-ghost,
button.btn.white-ghost {
  background: transparent;
  border: 1px solid white;
  color: white;
}

a.btn.white-ghost:hover,
button.btn.white-ghost:hover {
  color: white;
  background: rgba(255, 255, 255, 0.6);
}

a.btn.brown-ghost,
button.btn.brown-ghost {
  background: transparent;
  border: 1px solid #975010;
  color: #975010;
}

a.btn.brown-ghost:hover,
button.btn.brown-ghost:hover {
  color: white;
  background-color: #975010;
}

a.btn.rounded,
button.btn.rounded {
  border-radius: 5px;
}

a.btn.morado,
button.btn.morado {
  background: #852781;
  border: 1px solid transparent;
  color: white;
}

a.btn.morado:hover,
button.btn.morado:hover {
  background: white;
  color: #852781;
  border-color: #852781;
}

/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
.u__brown {
  color: #975010;
}

.u__paledarkerbrown {
  color: #625650;
}

.u__orange {
  color: #E37523;
}

.u__morado {
  color: #852781;
}

.u__white {
  color: white !important;
}

.u__espacio {
  color: #63072e !important;
}

.u__sense {
  color: #BDCFD1 !important;
}

.u__sensegrupos {
  color: #A6337F !important;
}

.u__violeta {
  color: #dfd7e8 !important;
}

.u__beige {
  color: #DCD4BB;
}

.u__facebook {
  color: #385b9b;
}

.u__twitter {
  color: #23aae1;
}

/*
|--------------------------------------------------------------------------
| Backgrounds
|--------------------------------------------------------------------------
*/
.u__beige-bg {
  background-color: #DCD4BB;
}

.u__beigelight-bg {
  background-color: #F6F4EE;
}

.u__sense-bg {
  background-color: #BDCFD1;
}

.u__groups-bg {
  background-color: #dee7e8;
}

.u__senselight-bg {
  background-color: #f4eff4;
}

.u__brown-bg {
  background-color: #975010;
}

.u__paledarkerbrown-bg {
  background-color: #625650;
}

.u__gray-bg {
  background-color: #6B5E58;
}

.u__morado-bg {
  background-color: #852781;
}

.u__violeta-bg {
  background-color: #dfd7e8;
}

.beige__linear-bg {
  background-image: linear-gradient(to right, transparent, #F6F4EE 10%, #F6F4EE 90%, transparent);
}

/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/
.fw100 {
  font-weight: 100;
}

.fw300 {
  font-weight: 300;
}

.fw400 {
  font-weight: 400;
}

.fw500 {
  font-weight: 500;
}

.fz12 {
  font-size: 12px;
}

.fz14 {
  font-size: 14px;
}

.fz16 {
  font-size: 16px;
}

.fz20 {
  font-size: 20px;
}

.fz25 {
  font-size: 25px;
}

.fz26 {
  font-size: 26px;
}

.fz30 {
  font-size: 30px;
}

.fz40 {
  font-size: 40px;
}

/*
|--------------------------------------------------------------------------
| Images
|--------------------------------------------------------------------------
*/
.mxw-65 {
  max-width: 65px;
}

.mxw-70 {
  max-width: 70px;
}

.mxw-90 {
  max-width: 90%;
}

.w100 {
  width: 100%;
}

.mxw-30 {
  max-width: 30px;
}

.mxw-100 {
  max-width: 100px;
}

.mxw-110 {
  max-width: 110px;
}

.mxw-130 {
  max-width: 130px;
}

.mxw-140 {
  max-width: 140px;
}

.mxw-170 {
  max-width: 170px;
}

.mxw-200 {
  max-width: 200px;
}

.mxh-100 {
  max-height: 100px;
}

.mxw30p {
  max-width: 30%;
}

/*
|--------------------------------------------------------------------------
| Shadows
|--------------------------------------------------------------------------
*/
.b-shadow {
  box-shadow: 0 4px 1px rgba(0, 0, 0, 0.2);
}

/*
|--------------------------------------------------------------------------
| Frames & Borders
|--------------------------------------------------------------------------
*/
.withBorder {
  border: 1px solid transparent;
}

.u__bd-brown {
  border-color: #975010;
}

.u__bd-violeta {
  border-color: #dfd7e8;
}

.u__bd-morado {
  border-color: #852781;
}

.u__bb_beige_1 {
  border-bottom: 1px solid #DCD4BB;
}

.u__bb_palebrown_1 {
  border-bottom: 1px solid #7e746e;
}

.u__bd-white {
  border-color: white;
}

/*
|--------------------------------------------------------------------------
| Colors Forced
|--------------------------------------------------------------------------
*/
.u__brown_f {
  color: #975010 !important;
}

.u__orange_f {
  color: #E37523 !important;
}

.u__morado_f {
  color: #852781 !important;
}

.u__white_f {
  color: white !important;
}

.u__espacio_f {
  color: #63072e !important;
}

.u__violeta_f {
  color: #dfd7e8 !important;
}

.u__inherit_f {
  color: inherit !important;
}

.u__bd-white_f {
  border-color: white !important;
}

/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/
header {
  background: url(/images/svg/patron-header.svg) top left;
  padding: 20px 0 0 0;
  position: relative;
  z-index: 10;
}

@media (min-width: 768px) {
  header {
    padding-bottom: 20px;
  }
}

header img.logo {
  max-width: 200px;
}

header ul.social-header {
  float: right;
}

header ul.social-header li a {
  color: #975010;
  font-size: 20px;
  display: block;
  margin: 0 10px;
}

header span.contacto-header a {
  color: #6B5E58;
  text-transform: uppercase;
  font-size: 12px;
}

header span.phone800 {
  margin-left: 1rem;
  margin-top: 1rem;
  font-weight: 400;
  color: #975010;
  font-size: 19px;
}

header span.phone800 small {
  font-weight: 300;
  font-size: 14px;
}

header nav #Hamburguer {
  cursor: pointer;
  margin: 1rem auto 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  background: #DCD4BB;
  padding: 1rem 0;
}

@media (min-width: 768px) {
  header nav #Hamburguer {
    display: none;
  }
}

header nav ul.Main__Navigation {
  background-color: #DCD4BB;
  display: none;
  list-style: none;
}

@media (min-width: 768px) {
  header nav ul.Main__Navigation {
    background-color: transparent;
    display: flex;
  }
}

header nav ul.Main__Navigation li.nav-item {
  position: relative;
  padding: 6px 0;
}

@media (min-width: 768px) {
  header nav ul.Main__Navigation li.nav-item {
    padding: 0;
  }
}

header nav ul.Main__Navigation li.nav-item.active a:after {
  width: 50%;
  background: #E37523;
}

header nav ul.Main__Navigation li.nav-item:hover ul.dropdown-content {
  display: block;
  opacity: 1;
  list-style: none;
}

header nav ul.Main__Navigation li.nav-item a {
  position: relative;
  display: block;
  position: relative;
  text-transform: uppercase;
  font-size: 13px;
  color: #444444;
}

header nav ul.Main__Navigation li.nav-item a:after {
  position: absolute;
  top: 110%;
  left: 50%;
  width: 0%;
  height: 4px;
  background-color: transparent;
  content: '';
  transform: translateX(-50%);
  border-radius: 4px;
  transition: all 0.4s;
}

header nav ul.Main__Navigation li.nav-item a:hover:after {
  background: #E37523;
  width: 50%;
}

header nav ul.Main__Navigation li.nav-item ul.dropdown-content {
  list-style: none;
  padding-left: 0;
  width: 100%;
  min-width: auto;
  display: none;
  position: absolute;
  background-color: #6B5E58;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

header nav ul.Main__Navigation li.nav-item ul.dropdown-content li {
  white-space: nowrap;
}

header nav ul.Main__Navigation li.nav-item ul.dropdown-content li a {
  padding: 10px 12px;
  text-decoration: none;
  display: block;
  color: white;
}

header nav ul.Main__Navigation li.nav-item ul.dropdown-content li a:hover {
  background-color: #E37523;
}

header nav ul.Main__Navigation li.nav-item ul.dropdown-content li a:after {
  display: none;
}

@media (max-width: 768px) {
  header nav ul.Main__Navigation li.nav-item ul.dropdown-content {
    position: relative;
    width: 100%;
  }
}

header .dropdown {
  position: relative;
  display: inline-block;
}

header .dropdown .dropdown-content {
  color: white;
  display: none;
  position: absolute;
  background-color: #6B5E58;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

header .dropdown .dropdown-content a {
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  color: white;
}

header .dropdown .dropdown-content a:hover {
  background-color: #E37523;
}

header .dropdown:hover .dropdown-content {
  display: block;
}

.navbar-header {
  float: none;
}

.modal-fullscreen-menu {
  background-color: #E37523;
  z-index: 1000;
  width: 100%;
}

.modal-fullscreen-menu .close {
  color: white;
  opacity: 1;
  padding: 10px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  font-size: 5vh;
}

.modal-fullscreen-menu .modal-dialog {
  margin: 0 auto;
  width: 100%;
  max-width: 768px;
  display: flex;
  height: 100%;
  align-items: center;
}

.modal-fullscreen-menu .modal-content {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

.modal-fullscreen-menu .list-group {
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.modal-fullscreen-menu .list-group ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.modal-fullscreen-menu .list-group ul li {
  position: relative;
}

.modal-fullscreen-menu .list-group ul li:after {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 100%;
  height: 1px;
  padding: 0px;
  display: inline-block;
  border-bottom: 1px solid white;
  opacity: 0.3;
}

.modal-fullscreen-menu .list-group ul li.ultimosm:after {
  content: '';
  position: absolute;
  bottom: 1px;
  left: 1px;
  width: 100%;
  height: 0px;
  padding: 0px;
  display: none;
  border: none;
}

.modal-fullscreen-menu .list-group a {
  display: -webkit-inline-box;
  display: inline-box;
  font-size: 150%;
  font-weight: 200;
  letter-spacing: 0.05em;
  border: none;
  transition: all 0.25s ease;
  background-color: transparent;
  color: white;
  padding: 10px;
  font-size: 3.5vh;
  line-height: 25px;
}

.modal-fullscreen-menu .list-group a:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #E37523;
  z-index: -1;
  opacity: 0;
  transform: scale3d(0.7, 1, 1);
  transition: transform 0.4s, opacity 0.4s;
}

.modal-fullscreen-menu .list-group a:hover {
  color: white;
}

.modal-fullscreen-menu .list-group a:hover:before {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

/*
|--------------------------------------------------------------------------
| Home
|--------------------------------------------------------------------------
*/
.Home__Habitaciones {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #F6F4EE;
  position: relative;
}

.Home__Habitaciones:after, .Home__Habitaciones:before {
  position: absolute;
  top: 1px;
  width: 100%;
  max-width: 400px;
  height: 358px;
}

.Home__Habitaciones:before {
  content: url(/images/svg/mosaico-left.svg);
  left: -396px;
}

.Home__Habitaciones:after {
  content: url(/images/svg/mosaico-right.svg);
  right: -386px;
}

.Home__Habitaciones h3.Habitaciones__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Home__Videos {
  padding-top: 15px;
  padding-bottom: 15px;
  background-image: url(/images/bg/home-videos.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #6B5E58;
  padding: 1rem 0;
}

.Home__Videos .home_video {
  position: relative;
  border: 1px solid white;
  padding: 10px;
  position: relative;
}

.Home__Videos .home_video img.frame {
  width: 100%;
  border: 1px solid white;
  padding: 10px;
}

.Home__Videos .home_video img.player {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

hr.shadowed {
  width: 100%;
  height: 1px;
  border: none;
  display: block;
  background-color: transparent;
  box-shadow: 0 -3px 4px rgba(0, 0, 0, 0.5);
}

hr.shadowed.inverted {
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.5);
}

.Home__Logos {
  padding-top: 15px;
  padding-bottom: 15px;
  overflow: hidden;
}

.Home__Logos hr.simpleLine {
  height: 1px;
  background-color: #DCD4BB;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
}

.Home__Ruta_stellar {
  background: url(/images/bg/ruta-queso-y-vino.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: 450px;
  position: relative;
}

.Home__Ruta {
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
  position: relative;
}

.Home__Ruta .container {
  z-index: 30;
}

.Home__Ruta:after {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.5;
  transition: opacity 0.7s;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Home__Ruta:hover:after {
  opacity: 0.3;
}

.Home__Ruta hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

.Home__Tequisquiapan {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #F6F4EE;
  position: relative;
}

.Home__Tequisquiapan:after, .Home__Tequisquiapan:before {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 385px;
  height: 100%;
}

.Home__Tequisquiapan:before {
  content: url(/images/svg/mosaico-tequis-left.svg);
  left: -385px;
}

.Home__Tequisquiapan:after {
  content: url(/images/svg/mosaico-tequis-right.svg);
  right: -390px;
}

.Home__Tequisquiapan .container {
  z-index: 100;
}

.Home__Tequisquiapan h3.Habitaciones__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Home__Grupos {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #dee7e8;
  position: relative;
}

.Home__Grupos:after, .Home__Grupos:before {
  position: absolute;
  top: 0;
  width: 100%;
  max-width: 385px;
  height: 100%;
}

.Home__Grupos:before {
  content: url(/images/svg/mosaico-tequis-left.svg);
  left: -385px;
}

.Home__Grupos:after {
  content: url(/images/svg/mosaico-tequis-right.svg);
  right: -390px;
}

.Home__Grupos .container {
  z-index: 100;
}

.Home__Grupos h3.Habitaciones__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Cena__romantica_stellar {
  background: url(/images/bg/cena-romantica.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}

.Cena__romantica {
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
  position: relative;
}

.Cena__romantica .container {
  z-index: 30;
}

.Cena__romantica:after {
  transition: opacity 0.6s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Cena__romantica:hover:after {
  opacity: 0.3;
}

.Cena__romantica hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

.Home__Globo_stellar {
  background: url(/images/bg/vuelo-en-globo.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
}

.Home__Globo {
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
  position: relative;
}

.Home__Globo .container {
  z-index: 30;
}

.Home__Globo:after {
  transition: opacity 0.6s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Home__Globo:hover:after {
  opacity: 0.3;
}

.Home__Globo hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

.Home__Map {
  padding-top: 15px;
  padding-bottom: 15px;
}

.Home__Map span.direccion {
  text-transform: uppercase;
  font-size: 30px;
  font-weight: 400;
  color: white;
}

.Home__Map select.mapaGoogle {
  vertical-align: super;
  margin: 10px 0 10px 13px;
  border-color: #6B5E58;
  height: 30px;
  color: #93837c;
}

.Home__Sense {
  padding-top: 15px;
  padding-bottom: 15px;
}

#CDSWIDGRN.widGRN .footer {
  height: unset !important;
}

#closeModal {
  position: absolute;
  top: -87px;
  right: 150px;
  color: white;
  font-size: 30px;
}

#closeModal a {
  color: white;
  font-size: inherit;
}

#closeModalMovil {
  position: fixed;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 30px;
}

#closeModalMovil a {
  color: white;
  font-size: inherit;
}

#contenedor-modal {
  position: relative;
}

.iframehomevid {
  height: 500px;
}

@media (max-width: 767px) {
  .iframehomevid {
    height: 200px;
  }
}

/*
|--------------------------------------------------------------------------
| Home Sliders
|--------------------------------------------------------------------------
*/
.Slider__Container {
  position: relative;
}

.Slider__Container .Booking__Home {
  background: rgba(107, 94, 88, 0.75);
  padding: 2rem 0;
  position: relative;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media (min-width: 640px) {
  .Slider__Container .Booking__Home {
    position: absolute;
  }
}

.Slider__Container .Home__Slider {
  margin-bottom: 0;
}

@media (min-width: 640px) {
  .Slider__Container .Home__Slider.slick-slider {
    margin-bottom: 30px;
  }
}

.Slider__Container .Home__Slider ul.slick-dots {
  bottom: 280px;
  display: none !important;
}

@media (max-width: 767px) {
  .Slider__Container .Home__Slider ul.slick-dots {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .Slider__Container .Home__Slider ul.slick-dots {
    bottom: 180px;
    display: block;
  }
}

@media (min-width: 992px) {
  .Slider__Container .Home__Slider ul.slick-dots {
    bottom: 135px;
    display: block;
  }
}

.Slider__Container .Home__Slider ul.slick-dots li button:before {
  font-size: 16px;
  background-color: #DCD4BB;
  border-radius: 50%;
  color: #DCD4BB;
  border: 1px solid #DCD4BB;
  opacity: 0.75;
}

.Slider__Container .Home__Slider ul.slick-dots li.slick-active button:before {
  background-color: #E37523;
  border-radius: 50%;
  color: #E37523;
  border: 1px solid #DCD4BB;
  opacity: 1;
}

.Slider__Container .slick-slide img {
  width: 100%;
}

.Habitaciones__Slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.Habitaciones__Slider ul.slick-dots {
  bottom: 30px;
}

.Habitaciones__Slider ul.slick-dots li button:before {
  background-color: #DCD4BB;
  border-radius: 50%;
  color: #DCD4BB;
  border: 1px solid #DCD4BB;
  opacity: 0.75;
}

.Habitaciones__Slider ul.slick-dots li.slick-active button:before {
  background-color: #E37523;
  border-radius: 50%;
  color: #E37523;
  border: 1px solid #DCD4BB;
  opacity: 1;
}

.Sense__Slider {
  background-color: white;
}

.Sense__Slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.Sense__Slider ul.slick-dots {
  bottom: 30px;
}

.Sense__Slider ul.slick-dots li button:before {
  background-color: #DCD4BB;
  border-radius: 50%;
  color: #DCD4BB;
  border: 1px solid #DCD4BB;
  opacity: 0.75;
}

.Sense__Slider ul.slick-dots li.slick-active button:before {
  background-color: #E37523;
  border-radius: 50%;
  color: #E37523;
  border: 1px solid #DCD4BB;
  opacity: 1;
}

.Paquetes__Slider.slick-dotted.slick-slider {
  margin-bottom: 4rem;
}

.Paquetes__Slider ul.slick-dots {
  bottom: -40px;
  text-align: center;
}

.Paquetes__Slider ul.slick-dots li button:before {
  background-color: #DCD4BB;
  border-radius: 50%;
  color: #DCD4BB;
  border: 1px solid #DCD4BB;
  opacity: 0.75;
}

.Paquetes__Slider ul.slick-dots li.slick-active button:before {
  background-color: #E37523;
  border-radius: 50%;
  color: #E37523;
  border: 1px solid #DCD4BB;
  opacity: 1;
}

.Paquetes__Slider div {
  position: relative;
}

.Paquetes__Slider div img {
  width: 94%;
  margin: 0 auto;
}

.Paquetes__Slider div .Promos__Content {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
}

.Paquetes__Slider div .Promos__Content:after {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  display: block;
  margin: auto;
  content: '';
  border: 1px solid white;
  background: rgba(107, 94, 88, 0.4);
  z-index: 50;
}

.Paquetes__Slider div .Promos__Content span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  z-index: 100;
  font-size: 16px;
  width: 90%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.7);
}

@media (min-width: 767px) {
  .Paquetes__Slider div .Promos__Content span {
    font-size: 20px;
    width: 70%;
  }
}

.Habitaciones__small {
  margin: 2rem 0;
  display: none;
  z-index: 100;
}

@media (min-width: 767px) {
  .Habitaciones__small {
    display: block;
  }
}

.Habitaciones__small .slick-current img {
  border: 2px solid #E37523;
  box-shadow: 0 0 1px black;
}

.Habitaciones__small .slick-current:focus {
  outline: none;
}

.newarrows {
  position: absolute;
  top: 34%;
  left: -1%;
  width: 100%;
  display: none;
  color: #DCD4BB;
  z-index: 10;
}

.newarrows.sense {
  color: #852781;
}

@media (min-width: 767px) {
  .newarrows {
    display: block;
  }
}

.newarrows i.fa {
  font-size: 36px;
  cursor: pointer;
  position: absolute;
  left: 0;
}

.newarrows i.fa.righticon {
  right: -2% !important;
  left: auto;
}

.Gal__Slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.Gal__Slider ul.slick-dots {
  bottom: 30px;
}

.Gal__Slider ul.slick-dots li button:before {
  background-color: #DCD4BB;
  border-radius: 50%;
  color: #DCD4BB;
  border: 1px solid #DCD4BB;
  opacity: 0.75;
}

.Gal__Slider ul.slick-dots li.slick-active button:before {
  background-color: #E37523;
  border-radius: 50%;
  color: #E37523;
  border: 1px solid #DCD4BB;
  opacity: 1;
}

.Gal__small {
  margin: 2rem 0;
  display: none;
  z-index: 100;
}

@media (min-width: 767px) {
  .Gal__small {
    display: block;
  }
}

.Gal__small .slick-current img {
  border: 2px solid #E37523;
  box-shadow: 0 0 1px black;
}

.Gal__small .slick-current:focus {
  outline: none;
}

.SenseHome__Slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.SenseHome__small {
  margin: 2rem 0;
  display: none;
  z-index: 100;
}

@media (min-width: 767px) {
  .SenseHome__small {
    display: block;
  }
}

.SenseHome__small .slick-current img {
  border: 2px solid #63072e;
  box-shadow: 0 0 1px black;
}

.SenseHome__small .slick-current:focus {
  outline: none;
}

.Tequis__Slider {
  background-color: white;
}

.Tequis__Slider.slick-dotted.slick-slider {
  margin-bottom: 0;
}

.Tequis__Slider ul.slick-dots {
  bottom: 30px;
}

.Tequis__Slider ul.slick-dots li button:before {
  background-color: #DCD4BB;
  border-radius: 50%;
  color: #DCD4BB;
  border: 1px solid #DCD4BB;
  opacity: 0.75;
}

.Tequis__Slider ul.slick-dots li.slick-active button:before {
  background-color: #E37523;
  border-radius: 50%;
  color: #E37523;
  border: 1px solid #DCD4BB;
  opacity: 1;
}

/*
|--------------------------------------------------------------------------
| Forms
|--------------------------------------------------------------------------
*/
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #93837c;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #93837c;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #93837c;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #93837c;
}

.Booking__Pages {
  background: rgba(107, 94, 88, 0.85);
  padding: 14px 0;
}

/*
|--------------------------------------------------------------------------
| Footer
|--------------------------------------------------------------------------
*/
footer {
  padding: 3rem 0;
  background: url(/images/svg/patron-footer.svg) top left;
  background-color: #6B5E58;
}

footer img.logo-footer {
  max-width: 270px;
}

footer address {
  color: white;
}

footer ul.social-footer {
  margin-top: 2rem;
}

footer ul.social-footer li:first-child a {
  margin-left: 0;
}

footer ul.social-footer li a {
  color: white;
  display: block;
  margin: 0 13px;
}

footer ul.social-footer li a i.fa {
  font-size: 26px;
}

footer hr.footer-separator {
  border: none;
  height: 2px;
  margin: 1.5rem auto;
  background-color: #87776f;
  width: 100%;
  display: block;
  clear: both;
}

footer ul.footer__phones li {
  color: white;
  margin-bottom: 1rem;
  font-size: 20px;
}

footer ul.footer__phones li i.fa {
  display: block;
  float: none;
  margin-right: 1rem;
  font-size: 24px;
}

@media (min-width: 640px) {
  footer ul.footer__phones li i.fa {
    float: left;
  }
}

footer .footer-brands {
  max-height: 60px;
}

footer a.footer-social {
  cursor: pointer;
  color: white;
  margin: 15px;
  font-size: 20px;
}

footer a.footer-social:hover {
  color: #E37523;
}

footer a {
  color: #E37523;
}

.footer-bottom {
  background-color: #4f4541;
  color: white;
  text-align: center;
  font-size: 13px;
  padding: 0;
  line-height: 20px;
}

.Spa__Section h3.Spa__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Spa__Section ul.lista-spa {
  list-style: none;
  color: #975010;
}

.Spa__Section ul.lista-spa li img {
  vertical-align: 1px;
  margin-right: 10px;
}

.Grupos__Section h3.Grupos__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Grupos__Section .divizq {
  padding-right: 0;
}

.Grupos__Section .divizq ul {
  list-style: none;
}

.Grupos__Section .divizq ul li {
  text-align: right;
  padding-right: 20px;
}

.Grupos__Section .divder {
  padding-left: 0;
}

.Grupos__Section .divder ul {
  padding-left: 0px;
  list-style: none;
}

.Grupos__Section .divder ul li {
  padding-left: 20px;
  text-align: left;
}

.Grupos__Section h4.List__Header {
  font-size: 18px;
}

.Grupos__Section h4.List__Header span {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
}

.Grupos__Section ul.grupos-list {
  list-style: none;
  padding-left: 1rem;
  margin-bottom: 0;
}

.Grupos__Section ul.grupos-list.gray {
  background-color: #F1F2F2;
  padding: 1rem;
  margin-bottom: 1rem;
}

.Grupos__Section ul.grupos-list.gray li:last-child {
  margin-bottom: 0;
}

.Grupos__Section ul.grupos-list li {
  margin-bottom: 14px;
  overflow: hidden;
  position: relative;
}

.Grupos__Section ul.grupos-list li img {
  max-width: 36px;
  float: left;
  margin: 0 10px 0 0;
}

.Grupos__Section ul.grupos-list li p {
  text-align: left;
  font-size: 13px;
  line-height: 1.4;
  margin-bottom: 0;
}

.Grupos__Section ul.grupos-list li ul {
  position: relative;
  list-style: none;
}

.Grupos__Section ul.grupos-list li ul:before {
  position: absolute;
  content: '';
  width: 1px;
  height: 73%;
  background: #F1F2F2;
  top: 0;
  left: 1rem;
  overflow: hidden;
}

@media (min-width: 576px) and (max-width: 767px) {
  .Grupos__Section ul.grupos-list li ul:before {
    height: 77%;
  }
}

.Grupos__Section ul.grupos-list li ul li {
  position: relative;
  overflow: visible;
}

.Grupos__Section ul.grupos-list li ul li:before {
  position: absolute;
  content: '';
  width: 24px;
  height: 1px;
  background: #F1F2F2;
  top: 50%;
  left: -24px;
}

.tit_capac {
  color: #975010;
  font-size: 20px;
  margin-top: 50px;
}

.Reuniones_empresariales {
  background: url(/images/bg/empresariales.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
}

.Reuniones_empresariales .container {
  z-index: 30;
}

.Reuniones_empresariales:after {
  transition: opacity 0.4s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Reuniones_empresariales:hover:after {
  opacity: 0.2;
}

.Reuniones_empresariales hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

.Banquetes {
  background: url(/images/bg/grupos.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
}

.Banquetes .container {
  z-index: 30;
}

.Banquetes:after {
  transition: opacity 0.4s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Banquetes:hover:after {
  opacity: 0.2;
}

.Banquetes hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

.Viajes_incentivo {
  background: url(/images/bg/incentivo.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
}

.Viajes_incentivo .container {
  z-index: 30;
}

.Viajes_incentivo:after {
  transition: opacity 0.4s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Viajes_incentivo:hover:after {
  opacity: 0.2;
}

.Viajes_incentivo hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

/*
|--------------------------------------------------------------------------
|	General
|--------------------------------------------------------------------------
*/
h1, h2, h3, h4 {
  color: black;
}

/*
|--------------------------------------------------------------------------
|	Admin Styles
|--------------------------------------------------------------------------
*/
@media (min-width: 767px) {
  nav.navbar ul.navbar-nav {
    margin-left: 0;
  }
}

nav.navbar ul.navbar-nav li a {
  color: white;
}

nav.navbar ul.navbar-nav li a:hover {
  color: #E37523;
}

/*
|--------------------------------------------------------------------------
|	Tables
|--------------------------------------------------------------------------
*/
table.table tbody tr td.toCenter {
  text-align: center;
}

table.table thead tr th.toCenter {
  text-align: center;
}

/*
|--------------------------------------------------------------------------
|	Forms
|--------------------------------------------------------------------------
*/
form .form-group label {
  font-weight: bold;
  margin-bottom: 0;
  color: black;
}

.Tequis__Section h3.Tequis__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Ruta_Queso {
  background: url(/images/bg/ruta-queso-y-vino.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
}

.Ruta_Queso .container {
  z-index: 30;
}

.Ruta_Queso:after {
  transition: opacity 0.4s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Ruta_Queso:hover:after {
  opacity: 0.2;
}

.Ruta_Queso hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}

.Globo__Section h3.Globo__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Contacto__Section h3.Contacto__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Contacto__Section h4.Contacto__Header {
  font-size: 25px;
  color: #975010;
  font-weight: 300;
}

.Contacto__Section i {
  margin-right: 10px;
}

.Queso__Section h3.Queso__Header {
  font-size: 30px;
  color: #975010;
  font-weight: 300;
}

.Quesocal__Section {
  background: #e3eaea;
}

.Quesocal__Section .tit_calendar {
  font-size: 20px;
  line-height: 40px;
  color: #90357b;
}

.Quesocal__Section .icono-calendar {
  max-width: 40px;
  float: left;
  padding: 5px;
}

.Quesocal__Section .tit_subcalendar {
  font-size: 15px;
  line-height: 20px;
  color: #E37523;
}

.Quesocal__Section em {
  font-size: 15px;
  color: #444444;
}

.Quesocal__Section .sinicono {
  margin-left: 40px;
}

.Instalaciones__Section h3.Instalaciones__Header {
  font-size: 25px;
  color: #444444;
  font-weight: 300;
  margin-top: 50%;
}

.Instalaciones__Section h3.Instalaciones__Header i {
  font-size: 40px;
}

.Instalaciones__Section .Instalaciones__seccionl {
  width: 60%;
}

.Instalaciones__Section .deco-medioambiente {
  background: url(/images/instalaciones/bloquedeco-medioambiente.png) no-repeat center center;
}

.Instalaciones__Section .para_sustentables {
  background: url(/images/bg/ruta-queso-y-vino.jpg);
  background-attachment: fixed;
  background-repeat: no-repeat;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  padding: 1rem 0;
}

.Instalaciones__Section .para_sustentables .container {
  z-index: 30;
}

.Instalaciones__Section .para_sustentables:after {
  transition: opacity 0.4s;
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  background-color: #975010;
  opacity: 0.4;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}

.Instalaciones__Section .para_sustentables:hover:after {
  opacity: 0.2;
}

.Instalaciones__Section .para_sustentables hr.whiteLine {
  height: 1px;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  clear: both;
  border: none;
  position: relative;
  z-index: 30;
}
