/*
|--------------------------------------------------------------------------
| Setters
|--------------------------------------------------------------------------
*/
.oh {
	overflow: hidden;
}
.pos-rel {
	position: relative;
}
/*
|--------------------------------------------------------------------------
| Buttons
|--------------------------------------------------------------------------
*/
a.btn,
button.btn {
	transition: all 0.7s;
	&:focus,
	&.active {
		outline: none;
	}
	&.orange {
		background: $orange;
		color: white;
		border: 1px solid transparent;
		&:hover {
			background: white;
			color: $orange !important;
			border-color: $orange;
		}
	}
	&.beige {
		background: $beige;
		border: 1px solid transparent;
		&:hover {
			background: white;
			color: $gray;
			border-color: $beige;
		}
	}
	&.orange-ghost {
		background: transparent;
		border: 1px solid $orange;
		&:hover {
			color: white;
			background: $orange;
		}
	}
	&.violeta-ghost {
		background: transparent;
		border: 1px solid $violeta;
		&:hover {
			color: white;
			background: $violeta;
		}
	}
	&.morado-ghost {
		background: transparent;
		border: 1px solid $morado;
		&:hover {
			color: white;
			background: $morado;
		}
	}
	&.white-ghost {
		background: transparent;
		border: 1px solid white;
		color: white;
		&:hover {
			color: white;
			background: rgba(255,255,255, 0.6);
		}
	}
	&.brown-ghost {
		background: transparent;
		border: 1px solid $brown;
		color: $brown;
		&:hover {
			color: white;
			background-color: $brown;
		}
	}
	&.rounded {
		border-radius: 5px;
	}
	&.morado {
		background: $morado;
		border: 1px solid transparent;
		color: white;
		&:hover {
			background: white;
			color: $morado;
			border-color: $morado;
		}
	}
}

/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
.u__brown {
	color: $brown;
}
.u__paledarkerbrown {
	color: $paledarkerbrown;
}
.u__orange {
	color: $orange;
}
.u__morado {
	color: $morado;
}
.u__white {
	color: white !important;
}
.u__espacio {
	color: $espacio !important;
}
.u__sense {
	color: $sense !important;
}
.u__sensegrupos {
	color: $sense-grupos !important;
}
.u__violeta {
	color: $violeta !important;
}
.u__beige {
	color: $beige;
}
.u__facebook {
	color: $azulfacebook;
}
.u__twitter {
	color: $azultwitter;
}

/*
|--------------------------------------------------------------------------
| Backgrounds
|--------------------------------------------------------------------------
*/
.u__beige-bg {
	background-color: $beige;
}
.u__beigelight-bg {
	background-color: $beige-bg;
}
.u__sense-bg {
	background-color: $sense;
}
.u__groups-bg {
	background-color: $sense-bg;
}
.u__senselight-bg {
	background-color: $violetalight;
}
.u__brown-bg {
	background-color: $brown;
}
.u__paledarkerbrown-bg {
	background-color: $paledarkerbrown;
}
.u__gray-bg {
	background-color: $gray;
}
.u__morado-bg {
	background-color: $morado;
}
.u__violeta-bg {
	background-color: $violeta;
}
.beige__linear-bg {
	background-image: linear-gradient(to right, transparent, $beige-bg 10%, $beige-bg 90%, transparent);
}

/*
|--------------------------------------------------------------------------
| Fonts
|--------------------------------------------------------------------------
*/
.fw100 {
	font-weight: 100;
}
.fw300 {
	font-weight: 300;
}
.fw400 {
	font-weight: 400;
}
.fw500 {
	font-weight: 500;
}
.fz12 {
	font-size: 12px;
}
.fz14 {
	font-size: 14px;
}
.fz16 {
	font-size: 16px;
}
.fz20 {
	font-size: 20px;
}
.fz25 {
	font-size: 25px;
}
.fz26 {
	font-size: 26px;
}
.fz30 {
	font-size: 30px;
}
.fz40 {
	font-size: 40px;
}

/*
|--------------------------------------------------------------------------
| Images
|--------------------------------------------------------------------------
*/
.mxw-65 {
	max-width: 65px;
}
.mxw-70 {
	max-width: 70px;
}
.mxw-90 {
	max-width: 90%;
}
.w100 {
	width: 100%;
}
.mxw-30 {
	max-width: 30px;
}
.mxw-100 {
	max-width: 100px;
}
.mxw-110 {
	max-width: 110px;
}
.mxw-130 {
	max-width: 130px;
}
.mxw-140 {
	max-width: 140px;
}
.mxw-170 {
	max-width: 170px;
}
.mxw-200 {
	max-width: 200px;
}
.mxh-100 {
	max-height: 100px;
}
.mxw30p {
	max-width: 30%;
}

/*
|--------------------------------------------------------------------------
| Shadows
|--------------------------------------------------------------------------
*/
.b-shadow {
	box-shadow: 0 4px 1px rgba(0, 0, 0, 0.2);
}

/*
|--------------------------------------------------------------------------
| Frames & Borders
|--------------------------------------------------------------------------
*/
.withBorder {
	border: 1px solid transparent;
}
.u__bd-brown {
	border-color: $brown;
}
.u__bd-violeta {
	border-color: $violeta;
}
.u__bd-morado {
	border-color: $morado;
}
.u__bb_beige_1 {
	border-bottom: 1px solid $beige;
}
.u__bb_palebrown_1 {
	border-bottom: 1px solid $palebrown;
}
.u__bd-white {
	border-color: white;
}

/*
|--------------------------------------------------------------------------
| Colors Forced
|--------------------------------------------------------------------------
*/
.u__brown_f {
	color: $brown !important;
}
.u__orange_f {
	color: $orange !important;
}
.u__morado_f {
	color: $morado !important;
}
.u__white_f {
	color: white !important;
}
.u__espacio_f {
	color: $espacio !important;
}
.u__violeta_f {
	color: $violeta !important;
}
.u__inherit_f {
	color: inherit !important;
}



.u__bd-white_f {
	border-color: white !important;
}


