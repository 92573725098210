/*
|--------------------------------------------------------------------------
| Header
|--------------------------------------------------------------------------
*/
header {
	background: url(/images/svg/patron-header.svg) top left;
	padding: 20px 0 0 0;
	position: relative;
	z-index: 10;
	@media (min-width: 768px) {
		padding-bottom: 20px;
	}
	img.logo {
		max-width: 200px;
	}
	ul.social-header {
		li {
			a {
				color: $brown;
				font-size: 20px;
				display: block;
				margin: 0 10px;
			}
		}
		float: right;
	}
	span.contacto-header {
		a {
			color: $gray;
			text-transform: uppercase;
			font-size: 12px;
		}
	}
	span.phone800 {
		margin-left: 1rem;
		margin-top: 1rem;
		font-weight: 400;
		color: $brown;
		font-size: 19px;
		small {
			font-weight: 300;
			font-size: 14px;
		}
	}
	nav {
		#Hamburguer {
			cursor: pointer;
			margin: 1rem auto 0 auto;
			width: 100%;
			text-align: center;
			display: block;
			background: $beige;
			padding: 1rem 0;
			@media (min-width: 768px) {
				display: none;
			}
		}
		ul.Main__Navigation {
			background-color: $beige;
			display: none;
			list-style: none;
			@media (min-width: 768px) {
				background-color: transparent;
				display: flex;
			}
			li.hasdropdown {

			}
			li.nav-item {
				position: relative;
				padding: 6px 0;
				@media (min-width: 768px) {
					padding: 0;
				}
				&.active {
					a {
						&:after {
							width: 50%;
							background: $orange;
						}
					}
				}
				&:hover {
					ul.dropdown-content {
						display: block;
						opacity: 1;
						list-style: none;
					}
				}
				a {
					position: relative;
					display: block;
					position: relative;
					text-transform: uppercase;
					font-size: 13px;
					color: $darkgray;
					&:after {
						position: absolute;
						top: 110%;
						left: 50%;
						width: 0%;
						height: 4px;
						background-color: transparent;
						content: '';
						transform: translateX(-50%);
						border-radius: 4px;
						transition: all 0.4s;
					}
					&:hover {
						&:after {
							background: $orange;
							width: 50%;
						}
					}
				}
				ul.dropdown-content {
					list-style: none;
					padding-left: 0;
					width: 100%;
					min-width: auto;
					display: none;
					position: absolute;
					background-color: $gray;
					min-width: 160px;
					box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
					z-index: 1;
					li {
						white-space: nowrap;
						// border-bottom: 1px solid $palebrown;
						a {
							padding: 10px 12px;
							text-decoration: none;
							display: block;
							color: white;
							&:hover {
								background-color: $orange;
							}
							&:after {
								display: none;
							}
						}
					}

					@media (max-width: 768px) {
						position: relative;
						width: 100%;
					}
				}
			}
		}
		// .modalsubmenu {
		// 	display: none;
		// }
	}
	.dropdown {
		position: relative;
		display: inline-block;
		.dropdown-content {
			color: white;
			display: none;
			position: absolute;
			background-color: $gray;
			min-width: 160px;
			box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
			z-index: 1;
			a {
				padding: 12px 16px;
				text-decoration: none;
				display: block;
				color: white;
				&:hover {
					background-color: $orange
				}
			}
		}
		&:hover {
			.dropdown-content {
				display: block;
			}
		}
	}
}


.navbar-header {
  float: none;
}

.modal-fullscreen-menu {
	background-color: $orange;
	z-index: 1000;
	width: 100%;

  .close {
    color: white;
    opacity: 1;
    padding: 10px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    font-size: 5vh;
  }

  .modal-dialog {
    margin: 0 auto;
    width: 100%;
    max-width: 768px;
    display: flex;
    height: 100%;
    align-items: center;
  }

  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: none;
  }

  .list-group {
    text-align: center;
    margin: 0 auto;
    width: 100%;

    ul {
    	margin: 0;
    	padding: 0;
		list-style: none;
	   
		li {
			position: relative;
			&:after {
				content: '';
				position: absolute;
				bottom: 1px;
				left: 1px;
				width: 100%;
				height: 1px;
				padding: 0px;
				display: inline-block;
				border-bottom: 1px solid white;
				opacity: 0.3;
			}
		}

		li.ultimosm {
			&:after {
				content: '';
				position: absolute;
				bottom: 1px;
				left: 1px;
				width: 100%;
				height: 0px;
				padding: 0px;
				display: none;
				border: none;
			}
		}
    }

    a {
    display: -webkit-inline-box;
    display: inline-box;
      font-size: 150%;
      font-weight: 200;
      letter-spacing: 0.05em;
      border: none;
      transition: all 0.25s ease;
      background-color: transparent;
      color: white;
      padding: 10px;
      // height: 5vh;
      font-size: 3.5vh;
      line-height: 25px;


      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $orange;
        z-index: -1;
        opacity: 0;
        transform: scale3d(0.7, 1, 1);
        transition: transform 0.4s, opacity 0.4s;
      }

      &:hover {
        color: white;

        &:before {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }
    }
  }
}

