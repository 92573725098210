/*
|--------------------------------------------------------------------------
|	Modal
|--------------------------------------------------------------------------
*/
.Home__Intro {
	top: 0;
	left: 0;
	position: fixed;
	width: 100%;
	background: rgba(79, 69, 65, 0.9);
	z-index: 1000;
	overflow: scroll;
	// height: auto;
	height: 100%;
	padding-top: 1rem;
	@media (min-width: 767px) {
		padding-top: auto;
	}
	&.remove {
		display: none !important;
	}
	.Home__Intro-container {
		position: relative;
		width: 100%;
		max-height: 180px;
		max-width: 300px;
		z-index: 1000;
		margin-bottom: 1rem;
		overflow: hidden;
		cursor: pointer;
		@media (min-width: 767px) {
			max-height: none;
			margin-bottom: 0;
		}
		&:after {
			top: 5%;
			left: 5%;
			position: absolute;
			width: 90%;
			height: 90%;
			content: '';
			border: 1px solid white;
			z-index: 1009;
		}
		img {
			width: 100%;
		}
		h3 {
			font-weight: 300;
			font-size: 20px;
			color: white;
			text-align: center;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			padding: 11px;
			z-index: 1001;
			@media (min-width: 767px) {
				top: auto;
				bottom: 10%;
				// font-size: 30px;
				padding: 1rem;
			}
			&.familiar {
				background: rgba(227, 117, 35, 0.8);
			}
			&.romantica {
				background: rgba(133, 39, 129, 0.7);
			}
			&.grupos {
				background: rgba(107, 94, 88, 0.7);
			}
		}
		.Entrar {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background: transparent;
			z-index: 2000;
			opacity: 0;
			transition: opacity 0.7s;
			span {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				text-align: center;
				width: 100%;
				color: white;
				z-index: 2000;
				text-transform: uppercase;
			}
		}
		&:hover {
			.Entrar {
				@media (max-width: 767px) {
					display: none;
				}
				opacity: 1;
				&.familiar {
					background: rgba(227, 117, 35, 0.6);
				}
				&.romantica {
					background: rgba(133, 39, 129, 0.5);
				}
				&.grupos {
					background: rgba(107, 94, 88, 0.5);
				}
			}
		}
	}
}


.modal-backdrop {
	opacity: 0.8;
}