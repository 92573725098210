/*
|--------------------------------------------------------------------------
| Colors
|--------------------------------------------------------------------------
*/
$brown: #975010;
$palebrown: #7e746e;
$paledarkerbrown: #625650;
$gray: #6B5E58;
$darkgray: #444444;
$lightgray: #F1F2F2;
$orange: #E37523;
$yellow: #EB9C1C;
$beige: #DCD4BB;
$beige-bg: #F6F4EE; // al 50% de opacidad
$sense: #BDCFD1;
$sense-bg: #dee7e8; // al 50% de opacidad
$morado: #852781;
$sense-grupos: #A6337F;
$violeta: rgb(223, 215,232);
$violetalight: #f4eff4;
$espacio: #63072e;
$azulverde: #e3eaea;
$azulfacebook: #385b9b;
$azultwitter: #23aae1;
$rosacalendar: #90357b;
